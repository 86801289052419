






















import { computed, defineComponent } from '@/plugins/composition';

export default defineComponent({
  name: 'AppBarIcon',
  props: {
    light: {
      type: Boolean,
      required: false,
    },
    dark: {
      type: Boolean,
      required: false,
    },
  },

  setup(props) {
    const stroke = computed(() => {
      if (props.light) return 'black';
      if (props.dark) return 'white';
      return 'white';
    });

    return {
      stroke,
    };
  },
});
